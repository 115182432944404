// @flow
import { isWorkerThread } from 'common/utils/webWorkerUtil';
import urlParse from 'url-parse';

const getScriptHostName = (): string => {
    if (!isWorkerThread()) {
        return urlParse(__webpack_public_path__).host;
    }

    // NOTE: self.location is WorkerLocation
    // location.href is of the form blob:https//
    // so we can't use host, it will be something like this
    /**
     * location: WorkerLocation {
     *   hash: ""
     *   host: ""
     *   hostname: ""
     *   href: "blob:https://xyz.locus-dev.com/72794855-2987-4ab2-ac3a-4b226bc33106"
     *   origin: "https://xyz.locus-dev.com"
     *   pathname: "https://xyz.locus-dev.com/72794855-2987-4ab2-ac3a-4b226bc33106"
     *   protocol: "blob:"
     * }
     */
    // so using origin and parsing to get hostname
    const { location } = (self: DedicatedWorkerGlobalScope);
    return urlParse(location.origin).host;
};

export default getScriptHostName;
