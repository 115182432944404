// @flow

export const isWorkerThread = (): boolean => self.document === undefined;

export const str2ab = (str: string): Uint8Array => {
    var uint8array = new TextEncoder().encode(str);
    return uint8array;
};

export const ab2str = (
    uint8array: Uint8Array,
    encoding: TextDecoder$availableEncodings = 'utf-8'
): string => {
    var str = new TextDecoder(encoding).decode(uint8array);
    return str;
};
